import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { parseISO } from "date-fns";
import { useLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";

import DateRangeField from "./date-range-field";
import DateRangeInput from "./date-range-input";
import { IRelativeDates, IDateFilter, TimeUnit } from "./date-filter.types";
import "./date-filter.scss";

/**
 * DateFilter is a field that when selected opens a date range form.
 * It uses an internal Formik instance to manage state however its output affects three fields on its parent form
 * @param allowTimeSelect       Allow the user to select the time as well as the date.
 * @param endDateName           End date field name.
 * @param relativeDateName      Optional field name of relative date field.
 * @param relativeTimeUnitRange Optional smallest and largest time unit period allowed for relative dates.
 * @param startDateName         Start date field name.
 */
const DateFilter: IDateFilter = function DateFilter({
    allowTimeSelect = true,
    endDateName,
    disabled = false,
    relativeDateName,
    relativeTimeUnitRange = [TimeUnit.Days, TimeUnit.Years],
    showResetButton = true,
    hasIcon = true,
    limitSelectionMonths,
    startDateName,
    onChange,
}) {
    /**
     * Ensure we can handle date strings or null values in forms
     * @param date, the date from an outer form
     */
    const parseDate = (date?: Date | string | null) => {
        if (!date) return undefined;
        if (typeof date === "string") return parseISO(date);
        return date;
    };

    const [isOpen, setIsOpen] = useState(false);
    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen(!isOpen);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        isOpen,
        onOutsideClick: close,
        onParentClose: close,
        triggerOffset: 3,
        placement: "bottom-start",
        ResizeObserver,
    });

    const dateFilerDropdownStyles = {
        ...layerProps.style,
        zIndex: 100,
    };

    return (
        <Field name={startDateName}>
            {(startField: FieldProps) => {
                const values = startField.form.values;
                const setValues = startField.form.setValues;
                const startFieldValue = parseDate(startField.field.value);
                const endFieldValue = parseDate(values[endDateName]);
                const relativeFieldValue: IRelativeDates | undefined = relativeDateName && values[relativeDateName];

                return (
                    <>
                        {renderLayer(
                            isOpen && (
                                <div ref={layerProps.ref} style={dateFilerDropdownStyles} role="menu">
                                    <DateRangeInput
                                        allowTimeSelect={allowTimeSelect}
                                        closeFilter={close}
                                        relativeTimeUnitRange={relativeTimeUnitRange}
                                        showResetButton={showResetButton}
                                        startDate={startFieldValue}
                                        endDate={endFieldValue}
                                        relativeDates={relativeFieldValue}
                                        limitSelectionMonths={limitSelectionMonths}
                                        isTogglable={typeof relativeDateName === "string"}
                                        onChange={({ startDate, endDate, relativeDates }) => {
                                            let dates: { [key: string]: Date | undefined | null | IRelativeDates } = {};

                                            if (typeof relativeDateName === "string") {
                                                dates = {
                                                    [startDateName]: relativeDates === null ? startDate : undefined,
                                                    [endDateName]: relativeDates === null ? endDate : undefined,
                                                    [relativeDateName]: relativeDates,
                                                };
                                            } else {
                                                dates = {
                                                    [startDateName]: startDate,
                                                    [endDateName]: endDate,
                                                };
                                            }
                                            setValues({
                                                ...values,
                                                ...dates,
                                            });
                                            if (typeof onChange === "function") {
                                                onChange();
                                            }
                                        }}
                                    />
                                </div>
                            )
                        )}

                        <DateRangeField
                            ref={triggerProps.ref}
                            onClick={toggle}
                            hasIcon={hasIcon}
                            relativeDates={relativeFieldValue}
                            startDate={startFieldValue}
                            endDate={endFieldValue}
                            disabled={disabled}
                        />
                    </>
                );
            }}
        </Field>
    );
};

export default DateFilter;
