import axios from "utilities/axios";
import Role from "constants/role";
import { AxiosError } from "axios";
import { IUser } from "redux/application.types";
import { Url } from "@edgetier/types";
import { useQuery, UseQueryOptions } from "react-query";

export interface IUserGetQueryParameters {
    readonly departmentId?: number | number[];
    readonly isOnline?: boolean;
    readonly onlyBasicDetails?: boolean;
    readonly orderBy?: string;
    readonly roleId?: Role | Role[];
    readonly skillId?: number | number[];
    readonly userId?: number | number[];
    readonly userStateId?: number | number[];
}

/**
 * Request users from the backend.
 * @param url             The URL to send the request to.
 * @param queryParameters Any query parameters to be passed to the request.
 * @returns An array of users.
 */
const requestUsers = async (url: string, queryParameters: {}) => {
    const { data } = await axios.get(url, { params: queryParameters });
    return data;
};

/**
 * A hook for requesting users.
 * @param queryParameters Any query parameters to be passed to the request.
 * @param options         Any options to be passed to the useQuery hook.
 */
export const useUsers = <T = IUser[]>(
    queryParameters: IUserGetQueryParameters = {},
    options: Omit<UseQueryOptions<IUser[], AxiosError, T>, "queryKey" | "queryFn"> = {}
) => {
    const url = Url.Users;
    return useQuery<IUser[], AxiosError, T>([url, queryParameters], () => requestUsers(url, queryParameters), options);
};
