import openSelectMenu from "./open-select-menu";
import userEvent from "@testing-library/user-event";
import { Matcher, screen, waitFor, within } from "@testing-library/react";

/**
 * Open a select menu and choose one or more options.
 * @param matcherOrMatchers One or more matchers to find labels.
 * @param menuMatcher       Optional matcher to find the menu in case there are multiple.
 */
const select = async (matcherOrMatchers: Matcher | Matcher[], menuMatcher?: Matcher | HTMLElement) => {
    const matchers = Array.isArray(matcherOrMatchers) ? matcherOrMatchers : [matcherOrMatchers];

    const list = await openSelectMenu(menuMatcher);

    // In case of a multiple selection, the apply button needs to be clicked too.
    const apply = screen.queryByRole("button", { name: /apply/i });

    // Select all items by label.
    for (const matcher of matchers) {
        userEvent.click(within(list).getByLabelText(matcher));

        // Wait for selection to happen.
        if (apply !== null) {
            await waitFor(() => typeof within(list).queryByLabelText(matcher) === "undefined");
        }
    }

    if (apply !== null) {
        userEvent.click(apply);
    }
};

export default select;
