import React, { useState } from "react";
import { Formik } from "formik";
import { faTimes, faCheck, faUndoAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Radio, Button, FieldError } from "@edgetier/components";
import { format } from "date-fns";

import { ToggleDateType } from "../date-filter.types";
import DateRangeDisplay from "../date-range-display";
import FixedDateRangeInput from "../fixed-date-range-input";
import RelativeDateRangeInput from "../relative-date-range-input";
import ShortcutDateRanges from "../shortcut-date-ranges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDateRangeInput, IDateRangeInputState } from "./date-range-input.types";
import { getDateSchema, getInitialDates } from "./date-range-input.utilities";

/**
 * DateRangeInput Holds state for two types of date pickers and shows one or the other IF "isToggleable" is true
 * @param closeFilter           A function to close the date filter.
 * @param endDate
 * @param isTogglable           Boolean, control whether to display the relativeDates and Fixed dates toggle.
 * @param onChange
 * @param relativeDates         If not present toggle will default to fixed, if present will default to relativeDates
 * @param relativeTimeUnitRange Smallest and largest date period allowed.
 * @param startDate
 */
const DateRangeInput: IDateRangeInput = function DateRangeInput({
    allowTimeSelect,
    closeFilter,
    endDate,
    isTogglable = false,
    onChange,
    relativeDates,
    relativeTimeUnitRange,
    showResetButton,
    limitSelectionMonths,
    startDate,
}) {
    const [initialValues] = useState<IDateRangeInputState>(getInitialDates(startDate, endDate, relativeDates));

    const handleSubmit = ({ toggleValue, ...values }: IDateRangeInputState) => {
        const isFixedDates = toggleValue === ToggleDateType.Fixed;
        onChange({
            relativeDates: isFixedDates ? null : values.relativeDates,
            startDate: values.startDate,
            endDate: values.endDate,
        });
        if (closeFilter) closeFilter();
    };

    const clearValues = () => {
        onChange({
            relativeDates: null,
            startDate: undefined,
            endDate: undefined,
        });
        if (closeFilter) closeFilter();
    };

    return (
        <Formik<IDateRangeInputState>
            initialValues={initialValues}
            validationSchema={getDateSchema(limitSelectionMonths)}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, setValues, values, submitForm, errors, touched, setErrors }) => {
                const isFixedDates = values.toggleValue === ToggleDateType.Fixed;

                return (
                    <div className="date-filter__input">
                        {isTogglable && (
                            <div className="date-filter__input__toggle">
                                <Radio name="toggleValue" option={ToggleDateType.Fixed} label="Fixed" />
                                <Radio name="toggleValue" option={ToggleDateType.Relative} label="Relative" />
                            </div>
                        )}

                        {typeof limitSelectionMonths === "number" && limitSelectionMonths > 0 && (
                            <div className="date-filter__limit-info">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <div className="date-filter__limit-info-message">
                                    Please limit your selection to no more than
                                    <>
                                        {limitSelectionMonths === 1
                                            ? ` ${limitSelectionMonths} month`
                                            : ` ${limitSelectionMonths} months`}
                                    </>
                                </div>
                            </div>
                        )}

                        {isFixedDates && (
                            <>
                                <FixedDateRangeInput
                                    startDate={values.startDate}
                                    endDate={values.endDate}
                                    onChange={(partialValues) => {
                                        setValues({ ...values, ...partialValues });
                                        // We don't want to show error message until submit fires
                                        // then we want to clear it on next change
                                        const { endDate, ...otherErrors } = errors;
                                        setErrors(otherErrors);
                                    }}
                                />
                                {errors.endDate && touched.endDate ? (
                                    <div className="date-filter__error">
                                        <FieldError name="endDate" />
                                    </div>
                                ) : (
                                    <DateRangeDisplay
                                        allowTimeSelect={allowTimeSelect}
                                        fromTime={
                                            allowTimeSelect && typeof values.startDate !== "undefined"
                                                ? format(values.startDate, "HH:mm")
                                                : undefined
                                        }
                                        toTime={
                                            allowTimeSelect && typeof values.endDate !== "undefined"
                                                ? format(values.endDate, "HH:mm")
                                                : undefined
                                        }
                                        startDate={values.startDate}
                                        endDate={values.endDate}
                                    />
                                )}
                            </>
                        )}

                        {!isFixedDates && (
                            <>
                                <div className="date-filter__relative-date">
                                    <ShortcutDateRanges
                                        onSelect={(newRelativeDates) =>
                                            setValues({ ...values, relativeDates: newRelativeDates })
                                        }
                                        timeUnitRange={relativeTimeUnitRange}
                                    />
                                    <RelativeDateRangeInput
                                        {...values.relativeDates}
                                        setFieldValue={setFieldValue}
                                        timeUnitRange={relativeTimeUnitRange}
                                    />
                                </div>
                                <FieldError name="endDate" />
                                <DateRangeDisplay
                                    startDate={values.startDate}
                                    fromTime={values.relativeDates.from.time}
                                    endDate={values.endDate}
                                    toTime={values.relativeDates.to.time}
                                />
                            </>
                        )}

                        <div className="date-filter__select-date__buttons">
                            {showResetButton && (
                                <Button
                                    className="button button--negative button--reset"
                                    icon={faUndoAlt}
                                    onClick={clearValues}
                                    styleName="negative"
                                    type="button"
                                >
                                    Clear
                                </Button>
                            )}

                            {closeFilter && (
                                <Button icon={faTimes} onClick={closeFilter} styleName="neutral" type="button">
                                    Cancel
                                </Button>
                            )}

                            <Button icon={faCheck} styleName="positive" type="button" onClick={submitForm}>
                                Apply
                            </Button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default DateRangeInput;
