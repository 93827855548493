import { Redirect } from "react-router-dom";

import View from "constants/view";
import Permitted from "components/permitted";

import { IProps } from "./permitted-route.types";

/**
 * Render a route if the current user has permission to use it, otherwise redirect to the login screen.
 * @param props Children components and allowed roles.
 * @returns     Child components or a redirect to the login screen.
 */
const PermittedRoute = ({ children, roleIds }: IProps) => (
    <Permitted roleIds={roleIds}>{(permitted) => (permitted ? children : <Redirect to={View.Login} />)}</Permitted>
);

export default PermittedRoute;
