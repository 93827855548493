import { ISetupState } from "redux/modules/setup/setup.types";

import { IActionsUnion } from "../../types";

import actions from "./setup-actions";
import SetupType from "./setup-type";

type ISetupAction = IActionsUnion<typeof actions>;

export const initialState = {};

/**
 * Handle changes to the setup state. The setup contains basic information that is used throughout the application, for
 * example, all languages supported by the system.
 * @param state  State before altering the setup data.
 * @param action Action to update the setup state.
 * @returns      State after altering the setup data.
 */
export default function (state: ISetupState = initialState, action: ISetupAction): ISetupState {
    switch (action.type) {
        case SetupType.Store:
            // eslint-disable-next-line no-case-declarations
            const { brands, languages, ...rest } = action.payload;

            return {
                ...state,
                data: {
                    ...rest,
                    brands: (Array.isArray(brands) ? brands : [])
                        .map((brand) => ({ ...brand, pattern: new RegExp(brand.pattern, "i") }))
                        .sort((one, two) => one.brand.toLowerCase().localeCompare(two.brand.toLowerCase())),
                    languages: languages
                        .slice()
                        .sort((one, two) => one.language.toLowerCase().localeCompare(two.language.toLowerCase())),
                },
            };
        default:
            return state;
    }
}
