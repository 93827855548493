import React, { Fragment } from "react";
import cx from "classnames";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { FormFieldType } from "@edgetier/types";
import { Button, FieldError } from "@edgetier/components";

import DynamicFormField from "../dynamic-form-field";
import ErrorFocus from "../error-focus";
import FieldTranslation from "../field-translation";
import MaximumLength from "../character-count";

import { IProps } from "./dynamic-form-field-set.types";

const DynamicFormFieldSet = ({
    axiosInstance,
    autoFocusFirstField,
    fieldId,
    fields,
    childMappings,
    languageId,
    labelColour,
    values,
    selectedFieldId,
    onSelectField,
    isSingleField = false,
    isDisabled = false,
}: IProps) => {
    const formComponent = fields[fieldId];
    const noValue = !values[formComponent.fieldName] || !values[formComponent.fieldName].length;
    const childFields = childMappings[fieldId];

    return (
        <fieldset disabled={isDisabled} key={formComponent.fieldName}>
            <div className="field-container">
                <div className="field">
                    <FieldTranslation languageId={languageId} translationLookup={formComponent.translationLookup}>
                        {(translation) => (
                            <Fragment>
                                {!isSingleField && (
                                    <label htmlFor={formComponent.fieldName} style={{ color: labelColour }}>
                                        {translation.formFieldTranslation}
                                    </label>
                                )}

                                <FieldError name={formComponent.fieldName} />

                                {"maximumLength" in formComponent.configuration &&
                                    typeof formComponent.configuration.maximumLength === "number" && (
                                        <MaximumLength
                                            length={values[formComponent.fieldName].toString().length}
                                            maximumLength={formComponent.configuration.maximumLength}
                                        />
                                    )}

                                <DynamicFormField
                                    axiosInstance={axiosInstance}
                                    autoFocus={autoFocusFirstField}
                                    formField={formComponent}
                                    languageId={languageId}
                                    translation={translation}
                                />

                                <ErrorFocus />
                            </Fragment>
                        )}
                    </FieldTranslation>
                </div>
                {typeof onSelectField === "function" && (
                    <div className="preview-edit-button-container">
                        <Button
                            onClick={onSelectField.bind(null, formComponent)}
                            icon={faPencilAlt}
                            styleName="primary"
                            type="button"
                            outline={selectedFieldId !== formComponent.formFieldId}
                            disabled={formComponent.formFieldTypeId !== FormFieldType.Select}
                        />
                    </div>
                )}
            </div>

            {Array.isArray(childFields) && childFields.length && (
                <div className={cx("form-children", { disabled: noValue })}>
                    {childFields.map((childFieldId) => (
                        <DynamicFormFieldSet
                            axiosInstance={axiosInstance}
                            key={childFieldId}
                            fieldId={childFieldId}
                            fields={fields}
                            childMappings={childMappings}
                            values={values}
                            languageId={languageId}
                            labelColour={labelColour}
                            selectedFieldId={selectedFieldId}
                            onSelectField={onSelectField}
                        />
                    ))}
                </div>
            )}
        </fieldset>
    );
};

export default DynamicFormFieldSet;
