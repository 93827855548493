import React, { useMemo } from "react";

import { TimeUnit } from "../date-filter.types";
import { makePresets } from "../utilities";

import ShortcutButton from "./shortcut-button";
import { IProps } from "./shortcut-date-ranges.types";
import "./shortcut-date-ranges.scss";

/**
 * Presents a number of buttons to quickly apply a predefined range
 * @param timeUnitRange Smallest and largest date period allowed.
 * @param onSelect      A function to apply the relativeDates state
 * @returns             Buttons to quickly select date ranges.
 */
function ShortcutDateRanges({ timeUnitRange, onSelect }: IProps) {
    const presets = useMemo(makePresets, []);
    return (
        <div className="date-filter__shortcut-ranges">
            {isWithinRange(TimeUnit.Days, timeUnitRange) && (
                <ShortcutButton onSelect={() => onSelect(presets.today)}>Today</ShortcutButton>
            )}

            {isWithinRange(TimeUnit.Weeks, timeUnitRange) && (
                <ShortcutButton onSelect={() => onSelect(presets.thisWeek)}>This week</ShortcutButton>
            )}

            {isWithinRange(TimeUnit.Months, timeUnitRange) && (
                <ShortcutButton onSelect={() => onSelect(presets.thisMonth)}>This month</ShortcutButton>
            )}

            {isWithinRange(TimeUnit.Years, timeUnitRange) && (
                <ShortcutButton onSelect={() => onSelect(presets.thisYear)}>This year</ShortcutButton>
            )}
        </div>
    );
}

/**
 * Check if the target time unit is within the range.
 * @param timeUnit      Target time unit.
 * @param timeUnitRange Smallest and largest date period allowed.
 * @returns             True if the time unit is between the smallest and largest allowed.
 */
function isWithinRange(timeUnit: TimeUnit, [startTimeUnit, endTimeUnit]: [TimeUnit, TimeUnit]) {
    return startTimeUnit <= timeUnit && endTimeUnit >= timeUnit;
}

export default React.memo(ShortcutDateRanges);
