import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";

import DateDisplay from "../date-display";

import { IProps } from "./date-range-display.types";
import TimeDisplay from "./time-display";
import "./date-range-display.scss";

const DateRangeDisplay = ({ allowTimeSelect, format, startDate, endDate, fromTime, toTime }: IProps) => {
    return (
        <div className="date-filter__range-display">
            <DateDisplay date={startDate} dateFormat={format} />
            {fromTime && <TimeDisplay allowTimeSelect={allowTimeSelect} time={fromTime} dateFieldName="startDate" />}
            <FontAwesomeIcon icon={faLongArrowAltRight} />
            <DateDisplay allowTimeSelect={allowTimeSelect} date={endDate} dateFormat={format} />
            {toTime && <TimeDisplay allowTimeSelect={allowTimeSelect} time={toTime} dateFieldName="endDate" />}
        </div>
    );
};

export default DateRangeDisplay;
