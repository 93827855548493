import Axios from "axios";
import Qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Url } from "@edgetier/types";
import { Spinner } from "@edgetier/components";

import View from "constants/view";
import Role from "constants/role";
import axios from "utilities/axios";
import { authenticationOperations } from "redux/modules/authentication";
import { toastOperations } from "redux/modules/toast";

import { IProps } from "./authenticate.types";
import "./authenticate.scss";

/**
 * After signing in, users are redirected to an authentication provider such as Salesforce, Zendesk etc. If that is
 * successful, they will redirect back to this page with information in the URL that needs to be passed on to our
 * backend.
 */
export class Authenticate extends React.PureComponent<IProps> {
    cancelTokenSource = Axios.CancelToken.source();

    /**
     * Send the OAuth code and other data to the backend.
     */
    async componentDidMount(): Promise<void> {
        try {
            // Send the authentication details to the backend. It will return the user's role ID and email address.
            const { data } = await axios.post<{ email: string; roleId: number }>(
                Url.ValidateUser,
                { data: Qs.parse(this.props.search, { ignoreQueryPrefix: true }) },
                { cancelToken: this.cancelTokenSource.token }
            );

            // The user has been authenticated. Save their details in the store.
            this.props.signIn(data.email, data.roleId);
            this.props.push(data.roleId === Role.Administrator ? View.ReportingChat : View.AgentHome);
        } catch (serverError) {
            if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                localStorage.clear();
                this.props.push(View.Login);
                this.props.showServerErrorToast("Login Request Failed", serverError);
            }
        }
    }

    /**
     * Cancel authentication requests when unmounting.
     */
    componentWillUnmount(): void {
        this.cancelTokenSource.cancel();
    }

    /**
     * Show some sort of loading indicator while the server works out if the user is authenticated.
     */
    render(): JSX.Element {
        return (
            <div className="authenticate">
                <Spinner fullPage={true} />
            </div>
        );
    }
}

export default connect(null, { ...authenticationOperations, ...toastOperations })(Authenticate);
