import Role from "constants/role";
import sortUsers from "utilities/sort-users";
import { IProps } from "./select-agent.types";
import { IUser } from "redux/application.types";
import { SelectField } from "@edgetier/select";
import { useUsers } from "hooks-for/users/use-users";

/**
 * Request agents and display them in select field for filtering something.
 * @param props.name            The name of the select field.
 * @param props.queryParameters Parameters for users route.
 * @param props.otherProps      Props that can be added to the select field.
 * @returns                     Agent select element.
 */
const SelectAgent = ({ name = "userId", queryParameters = { onlyBasicDetails: true }, ...otherProps }: IProps) => {
    const { data: users, isLoading } = useUsers(
        { roleId: [Role.Agent, Role.TeamLead], ...queryParameters },
        { select: (data) => data.sort(sortUsers) }
    );

    return (
        <SelectField<IUser, number>
            description="agent"
            getLabel={({ firstName, surname }) => `${firstName} ${surname}`}
            getValue={({ userId }) => userId}
            isLoading={isLoading}
            items={users || []}
            name={name}
            {...otherProps}
        />
    );
};

export default SelectAgent;
