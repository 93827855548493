import React from "react";

import Permitted from "components/permitted";

import { IProps } from "./permitted-hidden.types";

/**
 * Show or hide a component depending on whether the user has the right roles to access it or not.
 * @param props          Permitted roles and children to render.
 * @param props.children Children to render.
 * @param props.roleIds  Permitted role IDs to see the child component.
 * @returns              The children if the user is permitted, or nothing otherwise.
 */
const PermittedHidden = ({ children, roleIds }: IProps) => (
    <Permitted roleIds={roleIds}>{(permitted) => permitted && children}</Permitted>
);

export default PermittedHidden;
