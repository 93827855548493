import userEvent from "@testing-library/user-event";
import { Matcher } from "@testing-library/react";
import { screen } from "@testing-library/react";

/**
 * Open or close the menu.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           Menu button.
 */
const clickMenuButton = async (menuMatcher?: Matcher | HTMLElement) => {
    const menuButton =
        menuMatcher instanceof HTMLElement
            ? menuMatcher
            : typeof menuMatcher !== "undefined"
            ? screen.getByLabelText(menuMatcher)
            : screen.getByRole("menu");
    userEvent.click(menuButton);
};

export default clickMenuButton;
