import { FormFieldType } from "@edgetier/types";
import { unreachableCaseError } from "@edgetier/utilities";

import FormFieldSubmissionBooleanField from "./form-submission-boolean-field";
import FormFieldSubmissionNetPromoterScoreField from "./form-submission-net-promoter-score-field";
import FormFieldSubmissionNumericField from "./form-submission-numeric-field";
import FormFieldSubmissionSelectMultipleField from "./form-submission-select-multiple-field";
import FormFieldSubmissionSelectSingleField from "./form-submission-select-single-field";
import FormFieldSubmissionStarField from "./form-submission-star-field";
import FormFieldSubmissionTextareaField from "./form-submission-textarea-field";
import FormFieldSubmissionTextField from "./form-submission-text-field";
import FormFieldSubmissionTimestampField from "./form-submission-timestamp-field";
import { IProps } from "./form-submission-field.types";
import "./form-submission-field.scss";

/**
 * Figure out what component to render based on a form field submission field's type.
 * @param props.field The form field submission field being displayed.
 * @returns           Field displayed using the most appropriate component.
 */
const FormSubmissionField = ({ field }: IProps) => {
    switch (field.formFieldTypeId) {
        case FormFieldType.Boolean:
            return <FormFieldSubmissionBooleanField field={field} />;

        case FormFieldType.Buttons:
        case FormFieldType.Radios:
            return <FormFieldSubmissionSelectSingleField field={field} />;

        case FormFieldType.SourcedSelect:
        case FormFieldType.Checkboxes:
        case FormFieldType.Select:
            return <FormFieldSubmissionSelectMultipleField field={field} />;

        case FormFieldType.Email:
        case FormFieldType.Text:
            return <FormFieldSubmissionTextField field={field} />;

        case FormFieldType.Timestamp:
            return <FormFieldSubmissionTimestampField field={field} />;

        case FormFieldType.Textarea:
            return <FormFieldSubmissionTextareaField field={field} />;

        case FormFieldType.Integer:
        case FormFieldType.Float:
            return <FormFieldSubmissionNumericField field={field} />;

        case FormFieldType.Star:
            return <FormFieldSubmissionStarField field={field} />;

        case FormFieldType.NetPromoterScore:
            return <FormFieldSubmissionNetPromoterScoreField field={field} />;

        default:
            throw unreachableCaseError(field);
    }
};

export default FormSubmissionField;
