import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId } from "@edgetier/utilities";

import { IProps } from "./date-form-field.types";
import { format } from "date-fns";

/**
 * Render a date field.
 * @param props.formField   Date form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 Date input.
 */
const DateFormField = ({ formField, translation, ...inputProps }: IProps) => {
    const maximumDate = formField.configuration.maximumValue;
    const minimumDate = formField.configuration.minimumValue;
    return (
        <Field name={formField.fieldName}>
            {({ field, form }: FieldProps) => (
                <input
                    {...inputProps}
                    {...field}
                    aria-describedby={createFieldErrorId(field.name)}
                    aria-invalid={typeof form.errors[field.name] === "string"}
                    aria-required={formField.required}
                    autoComplete="off"
                    id={formField.fieldName}
                    max={typeof maximumDate === "string" ? format(new Date(maximumDate), "yyyy-MM-dd") : undefined}
                    min={typeof minimumDate === "string" ? format(new Date(minimumDate), "yyyy-MM-dd") : undefined}
                    name={field.name}
                    placeholder={translation.placeholderText || ""}
                    type="date"
                />
            )}
        </Field>
    );
};

export default DateFormField;
