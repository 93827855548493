import {
    FormFieldType,
    IDateFormComponent,
    IDateTimeFormComponent,
    ITimeFormComponent,
    ITimestampFormComponent,
} from "@edgetier/types";
import { isAfter, isBefore, isDate } from "date-fns";

import FormField from "./form-field";

/**
 * Timestamp field.
 */
export default class TimestampFormField extends FormField<
    ITimestampFormComponent | IDateFormComponent | ITimeFormComponent | IDateTimeFormComponent,
    string
> {
    /**
     * Clean the value before submitting it. Empty strings should be sent as a null.
     * @returns The value.
     */
    formatValue(value: string) {
        const trimmed = value.trim();
        return trimmed.length === 0 ? null : trimmed;
    }

    /**
     * Initialise the field with either a sensible default or a default provided by the backend. React requires empty
     * strings rather than null values for empty input fields.
     * @returns Default value if provided or an empty string.
     */
    getDefaultValue() {
        return typeof this.configuration.defaultValue === "string" ? this.configuration.defaultValue : "";
    }

    /**
     * Ensure that the value does not exceed the maximum.
     * @param value Current field value.
     * @returns     True if the maximum constraint is not defined or if the value is less than it.
     */
    isMaximumValueValid(value: string) {
        const { maximumValue } = this.configuration;
        if (typeof maximumValue === "string") {
            // If it's a time field, convert it to a date string for comparison purposes.
            const date = new Date(this.data.formFieldTypeId === FormFieldType.Time ? `2022-01-01T${value}` : value);
            const maximumDate = new Date(
                this.data.formFieldTypeId === FormFieldType.Time ? `2022-01-01T${maximumValue}` : maximumValue
            );
            return isDate(date) && isDate(maximumDate) && isBefore(date, maximumDate);
        }
        return true;
    }

    /**
     * Ensure that the value is not below the minimum.
     * @param value Current field value.
     * @returns     True if the minimum constraint is not defined or if the value is more than it.
     */
    isMinimumValueValid(value: string) {
        const { minimumValue } = this.configuration;
        if (typeof minimumValue === "string") {
            // If it's a time field, convert it to a date string for comparison purposes.
            const date = new Date(this.data.formFieldTypeId === FormFieldType.Time ? `2022-01-01T${value}` : value);
            const minimumDate = new Date(
                this.data.formFieldTypeId === FormFieldType.Time ? `2022-01-01T${minimumValue}` : minimumValue
            );
            return isDate(date) && isDate(minimumDate) && isAfter(date, minimumDate);
        }
        return true;
    }

    /**
     * Check for a blank value. This is used to display a "required" error.
     * @param value Current field value.
     * @returns     True if the value is not blank.
     */
    isNotBlank(value: string) {
        return typeof value === "string" && value.trim().length > 0;
    }

    /**
     * Validate the field's value.
     * @param value Current field value.
     * @returns     True if the field if valid.
     */
    isValid(value: string) {
        if (!this.isNotBlank(value)) {
            return true;
        }

        return this.isMinimumValueValid(value) && this.isMaximumValueValid(value);
    }
}
