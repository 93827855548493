import React from "react";

import { relativeFromDate, relativeToDate } from "../utilities";
import DateRangeDisplay from "../date-range-display";

import { IProps } from "./relative-date-range-display.types";

const RelativeDateRangeDisplay = ({ relativeDates }: IProps) => {
    const { from, to } = relativeDates;
    const startDate = relativeFromDate(new Date(), from.value, from.timeUnitId);
    const endDate = relativeToDate(new Date(), to.value, to.timeUnitId);

    return <DateRangeDisplay startDate={startDate} fromTime={from.time} endDate={endDate} toTime={to.time} />;
};

export default RelativeDateRangeDisplay;
