import React from "react";
import ReactLoadingSkeleton from "react-loading-skeleton";
import { FunctionComponent, memo } from "react";
import { IProps } from "./loading-skeleton.types";
import "./loading-skeleton.scss";

/**
 * Wrapper around React Loading Skeleton to provide some default props.
 * @param props Props to pass to React Loading Skeleton.
 * @returns     Loading skeleton.
 */
const LoadingSkeleton: FunctionComponent<IProps> = (props) => {
    return (
        <span aria-label="loading placeholder">
            <ReactLoadingSkeleton baseColor="#f2f5f5" highlightColor="#dfe6e6" {...props} />
        </span>
    );
};

export default memo(LoadingSkeleton);
