import classNames from "classnames";
import React, { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { IProps } from "./button.types";

/**
 * Themable button with an icon.
 * Render a button that is coloured to suit the client.
 * @returns Button element.
 */
const Button: FunctionComponent<IProps> = ({
    children,
    className,
    icon,
    isIconOnly,
    isLoading = false,
    isSmall = false,
    isUnstyled,
    outline = false,
    size,
    styleName,
    ...otherProps
}) => {
    return (
        <button
            className={classNames(className, {
                button: !isUnstyled,
                "button--outline": outline || isLoading,
                "button--icon": !isUnstyled && (isIconOnly === true || typeof children === "undefined"),
                "button--small": isSmall,
                [`button--${styleName}`]: typeof styleName === "string",
                [`button--${size}`]: typeof size === "string",
            })}
            disabled={isLoading}
            {...otherProps}
        >
            {typeof icon !== "undefined" && (
                <FontAwesomeIcon
                    fixedWidth={typeof isLoading !== "undefined"}
                    icon={isLoading ? faSpinner : icon}
                    spin={isLoading}
                    aria-label="button-icon"
                />
            )}
            {children && children}
        </button>
    );
};

export default memo(Button);
