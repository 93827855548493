import { Fragment } from "react";
import { useSelector } from "react-redux";

import Role from "constants/role";
import { IApplicationState } from "redux/types";

import { IProps } from "./permitted.types";

/**
 * Determine if a user is permitted to see/do something.
 * @param props          Props containing children to render and permission details.
 * @param props.children Function to take permission result that will render some children.
 * @param props.roleIds  Permitted role IDs for whatever is being rendered.
 * @returns                Child components.
 */
export const Permitted = ({ children, roleIds }: IProps) => {
    const roleId = useSelector(({ authentication }: IApplicationState) => authentication.roleId);
    return (
        <Fragment>
            {children(typeof roleId === "number" && (roleId === Role.Developer || roleIds.includes(roleId)))}
        </Fragment>
    );
};

export default Permitted;
