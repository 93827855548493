import clickMenuButton from "./get-menu-button";
import openSelectMenu from "./open-select-menu";
import { Matcher, screen } from "@testing-library/react";

/**
 * Get the text of the labels in the menu.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           Array of label text.
 */
const getSelectLabelsText = async (menuMatcher?: Matcher) => {
    await openSelectMenu(menuMatcher);
    const labels = screen.getAllByRole("option").map(({ textContent }) => textContent?.trim());
    clickMenuButton(menuMatcher);
    return labels;
};

export default getSelectLabelsText;
