import { useCallback, FunctionComponent, memo, useState } from "react";
import { AxiosError } from "axios";
import { compareDesc } from "date-fns";
import { doNothing } from "@edgetier/utilities";
import { SpinnerUntil } from "@edgetier/components";
import { Url } from "@edgetier/types";
import { useDispatch, useSelector } from "react-redux";
import urlJoin from "url-join";

import QueryHistoryTimeline from "components-for/query-history/query-history-timeline";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import { IApplicationState } from "redux/types";
import { IQueryHistoryItem, INote } from "redux/application.types";
import { toastOperations } from "redux/modules/toast";
import useQueryNotes from "hooks-for/query-history/use-query-notes";
import useSimpleQuery from "queries/use-simple-query";

import QueryHistoryItem from "./query-history-item";
import QueryHistoryHeader from "./query-history-header";
import { IProps } from "./query-history-thread.types";
import "./query-history-thread.scss";

/**
 * Show all notes and interactions belonging to a query.
 * @param props.interactions           The interactions that will be displayed in the thread.
 * @param props.initialOpenInteraction The interaction that's initially opened when the thread is rendered.
 * @param props.onUpdateQueryState     Optional function to call when the user changes the query's query state.
 * @returns                            View of a query's thread.
 */
const QueryHistoryThread: FunctionComponent<IProps> = ({
    interactions,
    openInteraction: initialOpenInteraction,
    onUpdateQueryState = doNothing,
}) => {
    const dispatch = useDispatch();

    /**
     * Display errors in a toast.
     * @param serverError Backend error.
     */
    const onError = (serverError: AxiosError) => {
        dispatch(toastOperations.showServerErrorToast("Interaction Search Failed", serverError));
    };

    // Request all notes in the same query.
    const notesQuery = useQueryNotes(initialOpenInteraction.queryId);
    const notesArray = Object.values(notesQuery.data ?? {});
    const notes = notesArray.sort((noteOne, noteTwo) => compareDesc(noteOne.dateTime, noteTwo.dateTime));

    // The currently open interaction or note.
    const [openInteraction, setOpenInteraction] = useState(initialOpenInteraction);

    const { blockedQueryStateEnabled } = useSelector(({ setup }: IApplicationState) => getFeatureToggles(setup));
    const { data: queryStateId } = useSimpleQuery<{ queryStateId: number }, number>(
        urlJoin(Url.Query, initialOpenInteraction.queryId.toString()),
        {
            enabled: blockedQueryStateEnabled,
            onError,
            select: ({ queryStateId }) => queryStateId,
        }
    );

    /**
     * Show a note or interaction when selected.
     * @param item Note or interaction.
     */
    const onSelectItem = useCallback((item: IQueryHistoryItem | undefined) => {
        if (typeof item !== "undefined" && ("interactionId" in item || "noteId" in item)) {
            setOpenInteraction(item);
        }
    }, []);

    return (
        <div className="query-history-thread">
            <SpinnerUntil<{
                queryStateId: number;
                notes: INote[];
            }>
                data={{ queryStateId, notes }}
                isReady={(typeof queryStateId === "number" || !blockedQueryStateEnabled) && Array.isArray(notes)}
            >
                {({ notes, queryStateId }) => (
                    <>
                        <QueryHistoryHeader
                            blockedQueryStateEnabled={blockedQueryStateEnabled}
                            interactions={interactions}
                            notes={notes}
                            onUpdateQueryState={onUpdateQueryState}
                            queryId={initialOpenInteraction.queryId}
                            isProactiveChat={
                                "websiteSessionInvitationId" in initialOpenInteraction
                                    ? typeof initialOpenInteraction.websiteSessionInvitationId === "string"
                                    : false
                            }
                            queryStateId={queryStateId}
                        />

                        <div className="query-history-thread__content">
                            <QueryHistoryTimeline
                                activeInteraction={null}
                                initialValue={openInteraction}
                                interactionDetailId={null}
                                interactions={interactions}
                                notes={Object.values(notes)}
                                onItemsChanged={onSelectItem}
                                onSelect={onSelectItem}
                                queryId={initialOpenInteraction.queryId}
                                showPreview={false}
                                submitOnChange={true}
                            />
                            <QueryHistoryItem queryHistoryItem={openInteraction} />
                        </div>
                    </>
                )}
            </SpinnerUntil>
        </div>
    );
};

export default memo(QueryHistoryThread);
