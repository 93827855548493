import clickMenuButton from "./get-menu-button";
import { Matcher } from "@testing-library/react";
import { screen, waitFor } from "@testing-library/react";

/**
 * Open a select menu and wait for it to finish loading.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           List of unselected items.
 */
const openSelectMenu = async (menuMatcher?: Matcher | HTMLElement) => {
    clickMenuButton(menuMatcher);
    const list = await waitFor(() => screen.getByRole("listbox"));
    await waitFor(() => screen.getAllByRole("option"));
    return list;
};

export default openSelectMenu;
