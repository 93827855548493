import { SplitTimestamp } from "@edgetier/client-components";
import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import BlankField from "../blank-field";

import { IProps } from "./form-submission-timestamp-field.types";
import "./form-submission-timestamp-field.scss";

/**
 * Display a timestamp form field submission. This is presented to users as a date picker.
 * @param props.field Timestamp form field submission.
 * @returns           Field title and formatted date.
 */
const FormSubmissionTimestampField = ({ field }: IProps) => (
    <tr>
        <td>
            <DetailsFieldTitle>{field.formFieldTranslation}</DetailsFieldTitle>
        </td>

        <td>
            {typeof field.value !== "string" ? (
                <BlankField />
            ) : (
                <DetailsFieldValue>
                    <SplitTimestamp date={new Date(field.value)} />
                </DetailsFieldValue>
            )}
        </td>
    </tr>
);

export default FormSubmissionTimestampField;
