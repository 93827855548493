import * as Yup from "yup";
import { differenceInMonths } from "date-fns";
import { IRelativeDates, ToggleDateType, IRelativeDate, TimeUnit } from "../date-filter.types";
import { relativeFromDate, relativeToDate } from "../utilities";

import { IDateRangeInputState } from "./date-range-input.types";

export const getInitialDates = (startDateIn?: Date, endDateIn?: Date, relativeDatesIn?: IRelativeDates) => {
    const relativeDates = relativeDatesIn ?? {
        from: { timeUnitId: TimeUnit.Days, value: 3, time: "00:00" },
        to: { timeUnitId: TimeUnit.Days, value: 0, time: "23:59" },
    };

    return {
        relativeDates,
        startDate: startDateIn || relativeFromDate(new Date(), relativeDates.from.value, relativeDates.from.timeUnitId),
        endDate: endDateIn || relativeToDate(new Date(), relativeDates.to.value, relativeDates.to.timeUnitId),
        toggleValue: relativeDatesIn ? ToggleDateType.Relative : ToggleDateType.Fixed,
    };
};

export const relativeDateSchema = Yup.object().shape<IRelativeDate>({
    time: Yup.string().required(),
    timeUnitId: Yup.number().required(),
    value: Yup.number().required(),
});

export const getDateSchema = (limitSelectionMonths?: number) => {
    return Yup.object().shape<IDateRangeInputState>({
        relativeDates: Yup.object<IRelativeDates>({
            from: relativeDateSchema.required(),
            to: relativeDateSchema.required(),
        }),
        startDate: Yup.date().required("A starting date is required"),
        endDate: Yup.date()
            .min(Yup.ref("startDate"), "Starting date must be before ending date")
            .test({
                name: "limit-date-selection",
                exclusive: false,
                message: `Range selection exceeded the limit of ${limitSelectionMonths} months`,
                test: function (value) {
                    if (
                        typeof limitSelectionMonths === "number" &&
                        limitSelectionMonths > 0 &&
                        typeof value !== "undefined"
                    ) {
                        const startDate = new Date(this.parent.startDate);
                        const endDate = new Date(value);

                        // Inclusive comparison
                        return differenceInMonths(endDate, startDate) + 1 <= limitSelectionMonths;
                    }
                    return true;
                },
            })

            .required("An ending date is required"),
        toggleValue: Yup.number().oneOf([ToggleDateType.Fixed, ToggleDateType.Relative]).required(),
    });
};
