import React, { FunctionComponent, useEffect, useState } from "react";
import { Arrow, useLayer } from "react-laag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import ResizeObserver from "resize-observer-polyfill";
import { doNothing } from "@edgetier/utilities";
import { Button } from "@edgetier/components";

import { IProps } from "./ellipsis-menu.types";
import EllipsisMenuProvider from "./ellipsis-menu-provider/ellipsis-menu-provider";
import "./ellipsis-menu.scss";

/**
 * A floating menu triggered by clicking an ellipsis icon.
 * @param props.children           The contents of the menu.
 * @param props.isButton           When true, the menu will be triggered by a button instead of an icon.
 * @param props.isLockedOpen       When true, the menu will not close if the user clicks outside of it.
 * @param props.isVerticalEllipsis When true, the menu will use a vertical ellipsis icon.
 * @param props.menuLabel          An optional label for the menu.
 * @param props.overflowContainer  Boolean indicating if the menu should break out of the container.
 * @param props.placement          An optional placement for suggesting where to position the menu.
 * @param props.useArrow           Use an arrow that points at the menu trigger.
 */
const EllipsisMenu: FunctionComponent<IProps> = ({
    children,
    isButton = false,
    isLockedOpen: initialIsLockedOpen = false,
    isVerticalEllipsis = false,
    menuLabel,
    overflowContainer = true,
    placement,
    useArrow = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLockedOpen, setIsLockedOpen] = useState(initialIsLockedOpen);

    const close = () => setIsOpen(false);

    // If the menu is unlocked, close it.
    useEffect(() => {
        if (!isLockedOpen) {
            close();
        }
    }, [isLockedOpen]);

    const { layerProps, arrowProps, renderLayer, triggerProps } = useLayer({
        auto: true,
        isOpen,
        onDisappear: isLockedOpen ? doNothing : close,
        onOutsideClick: isLockedOpen ? doNothing : close,
        onParentClose: close,
        overflowContainer,
        placement,
        ResizeObserver,
        triggerOffset: useArrow ? 12 : 5,
    });

    const menu = (
        <div className={classnames("ellipsis-menu", { "ellipsis-menu--closed": !isOpen })} {...layerProps}>
            <EllipsisMenuProvider
                context={{
                    close,
                    isOpen,
                    isLockedOpen,
                    lockOpen: () => setIsLockedOpen(true),
                    unlock: () => setIsLockedOpen(false),
                }}
            >
                {useArrow && (
                    <div className="ellipsis-menu__arrow" data-testid="ellipsis-menu-arrow">
                        <Arrow {...arrowProps} />
                    </div>
                )}
                <ul>{children}</ul>
            </EllipsisMenuProvider>
        </div>
    );

    const icon = isVerticalEllipsis ? faEllipsisV : faEllipsisH;
    const onClick = () => (isOpen ? close() : setIsOpen(true));

    return (
        <React.Fragment>
            {isButton && (
                <div {...triggerProps}>
                    <Button aria-label="ellipsis-menu" icon={icon} onClick={onClick}>
                        {menuLabel}
                    </Button>
                </div>
            )}

            {!isButton && (
                <div
                    aria-label="ellipsis-menu"
                    className="ellipsis-menu__trigger"
                    onClick={onClick}
                    role="button"
                    {...triggerProps}
                >
                    {typeof menuLabel !== "undefined" && <span className="ellipsis-menu__label">{menuLabel}</span>}
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}

            {isOpen && renderLayer(menu)}
        </React.Fragment>
    );
};

export default EllipsisMenu;
