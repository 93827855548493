import Axios from "axios";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Url } from "@edgetier/types";

import Role from "constants/role";
import { authenticationOperations } from "redux/modules/authentication";
import axios from "utilities/axios";
import { IApplicationState } from "redux/types";
import { IQueryEmail } from "redux/modules/email/email.types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { Modal, ModalContainer } from "shared/modal";

import Warnings from "./warnings";
import { IProps, IState, IStateProps } from "./sign-out.types";
import "./sign-out.scss";

/**
 * Sign out button.
 */
export class SignOut extends React.PureComponent<IProps, IState> {
    cancelTokenSource = Axios.CancelToken.source();
    state = { deferredQueryCount: 0 };

    /**
     * Cancel deferred query request.
     */
    componentWillUnmount(): void {
        this.cancelTokenSource.cancel();
    }

    /**
     * Request deferred queries for the user.
     * @returns List of deferred queries.
     */
    async countDeferredQueries(): Promise<number> {
        try {
            this.props.showLoadingBlocker(true);
            const configuration = { cancelToken: this.cancelTokenSource.token };
            const response = await axios.get<IQueryEmail[]>(Url.EmailsDeferred, configuration);
            return response.data.length;
        } catch (serverError) {
            return 0;
        } finally {
            this.props.hideLoadingBlocker(true);
        }
    }

    /**
     * Agents need to be warned about any deferred queries and/or active chats when they sign out.
     * @param showModal Method to show warnings in a modal.
     */
    async generateWarnings(showModal: VoidFunction): Promise<void> {
        const { chatCount, roleId } = this.props;
        if (typeof roleId !== "undefined" && [Role.Agent, Role.Developer, Role.TeamLead].includes(roleId)) {
            const deferredQueryCount = await this.countDeferredQueries();
            if (chatCount === 0 && deferredQueryCount === 0) {
                this.props.signOut();
            } else {
                this.setState({ deferredQueryCount }, () => showModal());
            }
        } else {
            this.props.signOut();
        }
    }

    /**
     * Display a sign out button in the navigation menu.
     */
    render(): JSX.Element {
        return (
            <ModalContainer>
                {({ hideModal, showModal }) => (
                    <Fragment>
                        <Modal>
                            <h2>Warning</h2>
                            <Warnings
                                chatCount={this.props.chatCount}
                                deferredQueryCount={this.state.deferredQueryCount}
                                hideModal={hideModal}
                                signOut={this.signOut.bind(this, hideModal)}
                            />
                        </Modal>
                        <div
                            className="navigation__sign-out"
                            onClick={this.generateWarnings.bind(this, showModal)}
                            role="button"
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} />
                            Sign Out
                        </div>
                    </Fragment>
                )}
            </ModalContainer>
        );
    }

    /**
     * Sign the user out of the application.
     */
    signOut(hideModal: VoidFunction): void {
        hideModal();
        this.props.signOut();
    }
}

/**
 * Get the user's role and number of ongoing chats from the store. Agents may be warned about deferred queries or
 * ongoing chats before signing out.
 * @returns State props containing the user's role.
 */
export function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        chatCount: Object.keys(state.chat.chats).length,
        roleId: state.authentication.roleId,
    };
}

export default connect(mapStateToProps, { ...authenticationOperations, ...loadingBlockerOperations })(SignOut);
