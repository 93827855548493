import { FunctionComponent, memo, useEffect, useState } from "react";
import { SpinnerUntil } from "@edgetier/components";
import { useDispatch } from "react-redux";

import QueryHistoryThread from "components-for/query-history/query-history-thread";
import getInteractionInformation from "utilities/get-interaction-information";
import { IInteractionSearchItem } from "components/interaction-search/interaction-search.types";
import useQueryInteractions from "hooks-for/query-history/use-query-interactions";
import { toastOperations } from "redux/modules/toast";

import { IProps } from "./fullscreen-query-history-thread.types";
import "./fullscreen-query-history-thread.scss";
import { Page } from "@edgetier/client-components";

/**
 * Request the interactions based on the query ID provided and display them in a query history thread.
 * The active interaction in the thread will be the one that matches with the interaction ID provided.
 * @param props.interactionId The ID of the interaction being requested.
 * @param props.queryId       The ID of the query that the interaction being viewed belongs to.
 */
const FullscreenQueryHistoryThread: FunctionComponent<IProps> = ({ interactionId, queryId }) => {
    const dispatch = useDispatch();
    const [openInteraction, setOpenInteraction] = useState<IInteractionSearchItem | null>(null);
    const { data: interactions } = useQueryInteractions(queryId);

    useEffect(() => {
        if (typeof interactions !== "undefined") {
            const interactionItem = interactions.find(
                (item) => getInteractionInformation(item).idValue === interactionId
            );

            if (typeof interactionItem !== "undefined") {
                setOpenInteraction(interactionItem);
            } else {
                dispatch(
                    toastOperations.showErrorToast(
                        "Interaction not found",
                        "The requested interaction could not be found"
                    )
                );
            }
        }
    }, [dispatch, interactions, interactionId]);

    return (
        <Page className="fullscreen-query-history-thread">
            <SpinnerUntil<{ interactions: IInteractionSearchItem[]; openInteraction: IInteractionSearchItem }>
                data={{ interactions, openInteraction }}
                isReady={Array.isArray(interactions) && openInteraction !== null}
            >
                {({ interactions, openInteraction }) => (
                    <QueryHistoryThread interactions={interactions} openInteraction={openInteraction} />
                )}
            </SpinnerUntil>
        </Page>
    );
};

export default memo(FullscreenQueryHistoryThread);
