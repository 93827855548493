import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import DateRangeDisplay from "../date-range-display";
import RelativeDateRangeDisplay from "../relative-date-range-display";
import { doNothing } from "@edgetier/utilities";
import { IProps } from "./date-range-field.types";

const DateRangeField = React.forwardRef<HTMLDivElement, IProps>(
    ({ onClick, startDate, endDate, relativeDates, hasIcon, disabled }, ref) => {
        return (
            <div
                className={classNames("field field-inline date-filter", {
                    "date-filter__has-icon": hasIcon,
                    "date-filter__disabled": disabled,
                })}
                ref={ref}
                onClick={() => (disabled ? doNothing() : onClick())}
            >
                <div className="date-filter__field">
                    {hasIcon && (
                        <div className="field-inline__icon">
                            <FontAwesomeIcon icon={faCalendar} />
                        </div>
                    )}
                    {relativeDates ? (
                        <RelativeDateRangeDisplay relativeDates={relativeDates} />
                    ) : (
                        <DateRangeDisplay startDate={startDate} endDate={endDate} />
                    )}
                </div>
            </div>
        );
    }
);

export default DateRangeField;
