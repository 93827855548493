import { useFormikContext } from "formik";
import React, { FunctionComponent, memo } from "react";
import TimePicker, { TimePickerValue } from "react-time-picker";
import { getHours, getMinutes, set } from "date-fns";

import { IProps } from "./time-display.types";
import "./time-display.scss";

import { IDateRangeInputState } from "~/date-filter/date-range-input/date-range-input.types";

/**
 * Display the time component of a date and allow the user to edit the time if allowTimeSelect is true.
 * @param props.allowTimeSelect Allow the user to select the time.
 * @param props.dateFieldName   The field name of the date being changed.
 * @param props.time            The time to display.
 */
const TimeDisplay: FunctionComponent<IProps> = ({ allowTimeSelect = false, dateFieldName, time }) => {
    const { setFieldValue, values } = useFormikContext<IDateRangeInputState>();
    const date = values[dateFieldName];

    /**
     * Set the value of the date field being changed based on the time that the user has choosen.
     * @param time The time that the user has selected from the picker.
     */
    const onChange = (time: TimePickerValue) => {
        if (time === null) return;

        if (typeof date !== "undefined") {
            const [hours, minutes] =
                typeof time === "string"
                    ? time.split(":").map((item) => parseInt(item))
                    : [getHours(time), getMinutes(time)];
            setFieldValue(dateFieldName, set(date, { hours, minutes }));
        }
    };

    return (
        <span className="date-filter__range-display__time">
            {allowTimeSelect ? (
                <TimePicker
                    clearIcon={null}
                    disableClock
                    format="HH:mm"
                    value={date ?? time}
                    required
                    onChange={onChange}
                />
            ) : (
                time
            )}
        </span>
    );
};

export default memo(TimeDisplay);
