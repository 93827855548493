import classNames from "classnames";
import format from "date-fns/format";
import React from "react";

import { EMPTY_DATE_DISPLAY, EMPTY_DATE_TIME_DISPLAY } from "./date-display.constants";
import { IProps } from "./date-display.types";
import "./date-display.scss";

/**
 * Display a date selected in the date filter.
 * @param props.date Selected date which could be undefined.
 * @returns          Formatted date.
 */
const DateDisplay = ({ allowTimeSelect, date, dateFormat = "d/M/yy" }: IProps) => {
    const emptyDateDisplay = allowTimeSelect ? EMPTY_DATE_TIME_DISPLAY : EMPTY_DATE_DISPLAY;
    return (
        <div
            className={classNames("date-filter__date-display", {
                "date-filter__date-display--empty": typeof date === "undefined",
            })}
        >
            {!(date instanceof Date) ? emptyDateDisplay : format(date, dateFormat)}
        </div>
    );
};

export default DateDisplay;
