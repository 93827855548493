import maximumDate from "date-fns/max";
import minimumDate from "date-fns/min";
import { FunctionComponent, memo } from "react";

import UpdateQueryState from "../update-query-state";

import QueryHistoryTitleDates from "./query-history-title-dates";
import QueryHistoryTitleDescription from "./query-history-title-description";
import { IProps } from "./query-history-header.types";
import "./query-history-header.scss";

/**
 * Descriptive title for a query's thread. This will state the interaction types, booking ID, and dates.
 * @param props.interactions All interactions in a given thread.
 * @param props.notes        All notes in a give thread.
 * @returns                  Thread title.
 */
const QueryHistoryHeader: FunctionComponent<IProps> = ({
    blockedQueryStateEnabled,
    interactions,
    notes,
    onUpdateQueryState,
    queryId,
    queryStateId,
    isProactiveChat,
}) => {
    // Take any booking ID from the thread as they should hopefully all be the same.
    const bookingId = interactions.find(({ bookingId }) => bookingId !== null)?.bookingId;

    // Combine interaction and note dates.
    const interactionDates = interactions.map(({ dateTime }) => dateTime);
    const noteDates = notes.map(({ dateTime }) => dateTime);
    const dates = interactionDates.concat(noteDates);

    // All interaction types in the thread.
    const interactionTypeIds = interactions.map(({ interactionTypeId }) => interactionTypeId);

    return (
        <div className="query-history-header">
            <h2 className="query-history-header__title">
                <QueryHistoryTitleDescription
                    interactionTypeIds={interactionTypeIds}
                    isProactiveChat={isProactiveChat}
                />{" "}
                Thread
                <span>
                    {bookingId && (
                        <>
                            <span>&nbsp;&ndash;&nbsp;</span>
                            {bookingId}
                        </>
                    )}
                    {dates.length > 1 && (
                        <>
                            <span>&nbsp;&ndash;&nbsp;</span>
                            <QueryHistoryTitleDates
                                firstDateTime={minimumDate(dates)}
                                lastDateTime={maximumDate(dates)}
                            />
                        </>
                    )}
                </span>
            </h2>
            {blockedQueryStateEnabled && (
                <UpdateQueryState
                    onUpdateQueryState={onUpdateQueryState}
                    queryId={queryId}
                    queryStateId={queryStateId}
                />
            )}
        </div>
    );
};

export default memo(QueryHistoryHeader);
