import clickMenuButton from "./get-menu-button";
import { Matcher } from "@testing-library/react";

/**
 * Close a select menu.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           List of unselected items.
 */
const closeSelectMenu = async (menuMatcher?: Matcher | HTMLElement) => {
    clickMenuButton(menuMatcher);
};

export default closeSelectMenu;
