import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId } from "@edgetier/utilities";

import { IProps } from "./time-form-field.types";

/**
 * Render a time field.
 * @param props.formField   time form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 time input.
 */
const TimeFormField = ({ formField, translation, ...inputProps }: IProps) => {
    const maximumTime = formField.configuration.maximumValue;
    const minimumTime = formField.configuration.minimumValue;
    return (
        <Field name={formField.fieldName}>
            {({ field, form }: FieldProps) => (
                <input
                    {...inputProps}
                    {...field}
                    aria-describedby={createFieldErrorId(field.name)}
                    aria-invalid={typeof form.errors[field.name] === "string"}
                    aria-required={formField.required}
                    autoComplete="off"
                    id={formField.fieldName}
                    max={typeof maximumTime === "string" ? maximumTime : undefined}
                    min={typeof minimumTime === "string" ? minimumTime : undefined}
                    name={field.name}
                    placeholder={translation.placeholderText || ""}
                    type="time"
                />
            )}
        </Field>
    );
};

export default TimeFormField;
